import React from 'react';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { faFire , faGamepad } from '@fortawesome/free-solid-svg-icons'

export default function Home() {

    const [t] = useTranslation('home');

    return (
        <main className="home">
            <div className="introduction">
                <h1>{t('home.h1')}</h1>
                <p>{t('home.introduction')}</p>
            </div>
            <div className="horizontal-line-1"></div>
            <section className="wip">
                <h2><FontAwesomeIcon icon={faFire} />{t('home.wip.aboutsite')}</h2>
                <p>{t('home.wip.whatsnext')}</p>
            </section>
            <div className="horizontal-line-2"></div>
            <section className="nextproject">
                <h2><FontAwesomeIcon icon={faPenToSquare} />{t('home.wip.workingon')}</h2>
                <ul>
                    <li>{t('home.wip.project1')}</li>
                    {t('home.wip.project2') !== '' ? <li>{t('home.wip.project2')}</li> : null}
                    {t('home.wip.project3') !== '' ? <li>{t('home.wip.project3')}</li> : null}
                </ul>
            </section>
            <div className="horizontal-line-2"></div>
            <section className="nowplaying">
                <h2><FontAwesomeIcon icon={faGamepad} />{t('home.wip.nowplaying')}</h2>
                <ul>
                    <li><span className="gamename">{t('home.wip.game1')} - </span>{t('home.wip.game1flavor')}</li>
                    {t('home.wip.game2') !== '' ? <li><span className="gamename">{t('home.wip.game2')} - </span>{t('home.wip.game2flavor')}</li> : null}
                    {t('home.wip.game3') !== '' ? <li><span className="gamename">{t('home.wip.game3')} - </span>{t('home.wip.game3flavor')}</li> : null}
                </ul>
            </section>
        </main>
    )
}