import React from 'react';
import { useTranslation } from "react-i18next";

export default function About() {

    const [t] = useTranslation('about');

    return (
        <main className="about">
            <div className="introduction">
                <h1>{t('home.h1')}</h1>
            </div>
            <div className="horizontal-line-1"></div>
            <div className="mystory">
                <div>
                    <h2>{t('home.title1')}</h2>
                    <div>    
                        <p>{t('home.text1')}</p>
                        <img className="aboutimage" src="./images/itsyaboi.jpg" />
                    </div>
                </div>
                <div className="horizontal-line-2"></div>
                <div>
                    <h2>{t('home.title2')}</h2>
                    <div>
                        <img className="aboutimage" src="./images/openclassrooms.jpg" />
                        <p>{t('home.text2')}</p>
                    </div>
                </div>
                <div className="horizontal-line-2"></div>
                <div>
                    <h2>{t('home.title3')}</h2>
                    <div>
                        <p>{t('home.text3')}</p>
                        <img className="aboutimage" src="./images/thecats.jpg" />
                    </div>
                </div>
                <div className="horizontal-line-2"></div>
                <div>
                    <h2>{t('home.title4')}</h2>
                    <div>
                        <img className="aboutimage" src="./images/smash.jpg" />
                        <p>{t('home.text4')}</p>
                    </div>
                </div>
            </div>
        </main>
    )
}