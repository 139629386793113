import Home from './pages/Home';
import Projects from './pages/Projects';
import Experience from './pages/Experience';
import About from './pages/About';
import Menu from './components/Menu';

import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

function App() {

  const [theme, setTheme] = useState([]);

  window.onload = () => {
    if (localStorage.getItem("theme") === "dark") {
      setTheme("dark");
    }
  }

  if (theme === "dark") {
    document.getElementById('darkmode').removeAttribute('disabled');
  }
  if (theme === "light") {
    document.getElementById('darkmode').disabled = true;
  }

  const handleChangeTheme = (e) => {
    if (theme !== "dark") {
      setTheme("dark")
      localStorage.setItem("theme", "dark")
    }
    else {
      setTheme("light")
      localStorage.setItem("theme", "light")
    }
    // setTheme(e.target.id)
    // localStorage.setItem("theme", theme)
  }

  return (
    <>
      <Menu handleChangeTheme={handleChangeTheme}/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/about" element={<About />}/>
        </Routes>
    </>
  );
}

export default App;
