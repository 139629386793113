import React from 'react';
import { useTranslation } from "react-i18next";

export default function Experience() {
    
    const [t] = useTranslation('experience');

    const otherExperiences = [
        ['lmp', '2022', 'Béziers, Montpellier (Hérault, France)'],
        ['connexiongraphique', '2021', 'Montpellier (Hérault, France)'],
        ['micromania', '2014-2020', 'Balaruc, Béziers, Montpellier, Pérols (Hérault, France), Narbonne (Aude, France), La Défense (Hauts-de-Seine, France), Massy-Palaiseau (Essonne, France), Aubervilliers (Seine-Saint-Denis, France)'],
        ['sete', '2014', 'Sète (Hérault, France)'],
        ['carrefour', '2010-2013', 'Balaruc (Hérault, France)']
    ]
    const assoExperiences = [
        ['smashalez', '2019-2022']
    ]
    let i = 1
    return (
        <main className="experience">
            <div className="introduction">
                <h1>{t('experience.h1')}</h1>
            </div>
            <div className="horizontal-line-1"></div>
            <section>
                <h2>{t('experience.devweb.h2')}</h2>
                <p>{t('experience.devweb.desc')}</p>
            </section>
            <div className="horizontal-line-1"></div>
            <section>
                <h2>{t('experience.other.h2')}</h2>
                <p>{t('experience.other.desc')}</p>
                <div className="horizontal-line-2"></div>
                {
                    otherExperiences.map(job => (
                        <>
                            <article className="job job--other">
                                <h3>{job[1] + ' | ' + t('experience.other.' + job[0] + '.name')}</h3>
                                <p className="job__function">{t('experience.other.' + job[0] + '.function')}</p>
                                <p className="job__where">{job[2]}</p>
                                <p className="job__desc">{t('experience.other.' + job[0] + '.desc')}</p>
                            </article>
                            {otherExperiences.length === i ? null : <div className="horizontal-line-2"></div>}
                            <div hidden>{i++}</div>
                        </>
                    ))
                }
            </section>
            <div className="horizontal-line-1"></div>
            <section>
                <h2>{t('experience.asso.h2')}</h2>
                <p>{t('experience.asso.desc')}</p>
                <div className="horizontal-line-2"></div>
                {
                    assoExperiences.map(asso => (
                        <article className="job job--other">
                            <h3>{asso[1] + ' | ' + t('experience.asso.' + asso[0] + '.name')}</h3>
                            <p className="job__function">{t('experience.asso.' + asso[0] + '.function')}</p>
                            <p className="job__activity">{t('experience.asso.' + asso[0] + '.activity')}</p>
                            <p className="job__desc">{t('experience.asso.' + asso[0] + '.desc')}</p>
                        </article>
                    ))
                }
            </section>
        </main>
    )
}