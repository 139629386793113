import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb , faFileCode , faCircleUser , faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons'
import { faHouse , faBriefcase , faCodeBranch , faPhone , faAt , faUsers } from '@fortawesome/free-solid-svg-icons'

export default function Menu(props) {

    const { handleChangeTheme } = props;

    const {t, i18n} = useTranslation('menu');

    return (
        <header>
            <div className="contact">
                <h2>{t('menu.contact.title')}:</h2>
                <p><span className="contactInfo"><FontAwesomeIcon icon={faEnvelopeOpen} /> {t('menu.contact.adress')}: </span>4 rue des Tuileries, <br />
                34500 Béziers (France)</p>
                <p><span className="contactInfo"><FontAwesomeIcon icon={faPhone} /> {t('menu.contact.phone')}: </span>(+33)6 42 25 11 85</p>
                <p><span className="contactInfo"><FontAwesomeIcon icon={faAt} /> {t('menu.contact.mail')}: </span>contact@tomtournillon.com</p>
                <p><a href="https://github.com/leCharade" target="_blank"><FontAwesomeIcon icon={faCodeBranch} />  {t('menu.contact.github')}</a></p>
                <p><a href="https://linkedin.com/in/tomtournillon" target="_blank"><FontAwesomeIcon icon={faUsers} />  {t('menu.contact.linkedin')}</a></p>
            </div>
            <ul className="menu">
                <li><NavLink to="/" className={({ isActive }) => (isActive ? "activeSection" : undefined)}><FontAwesomeIcon icon={faHouse} /> {t('menu.links.home')}</NavLink></li>
                <li><NavLink to="/projects" className={({ isActive }) => (isActive ? "activeSection" : undefined)}><FontAwesomeIcon icon={faFileCode} /> {t('menu.links.projects')}</NavLink></li>
                <li><NavLink to="/experience" className={({ isActive }) => (isActive ? "activeSection" : undefined)}><FontAwesomeIcon icon={faBriefcase} /> {t('menu.links.experience')}</NavLink></li>
                <li><NavLink to="/about" className={({ isActive }) => (isActive ? "activeSection" : undefined)}><FontAwesomeIcon icon={faCircleUser} /> {t('menu.links.about')}</NavLink></li>
            </ul>
            <div className="settings">
                <div className="setting setting--language">
                    <p>{t('menu.language')}</p>
                    <ul>
                        <li className="language language--FR" id="fr" onClick={() => i18n.changeLanguage('fr')}>FR</li>
                        <li className="language language--EN" id="en" onClick={() => i18n.changeLanguage('en')}>EN</li>
                    </ul>
                </div>
                <div className="setting setting--theme">
                    <p>{t('menu.theme.theme')} <FontAwesomeIcon onClick={handleChangeTheme} icon={faLightbulb} /></p>
                </div>
            </div>
        </header>
    )
}