import React from 'react';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-regular-svg-icons'
import { faCodeBranch , faArrowPointer } from '@fortawesome/free-solid-svg-icons'

export default function Projects() {

    const [t] = useTranslation('projects');

    const projects = [
        ['site', '', ['ReactJS', 'API', 'NodeJS', 'JavaScript', 'Sass', 'HTML', 'CSS']],
        ['groupomania', 'https://github.com/leCharade/groupy', ['ReactJS', 'API', 'NodeJS', 'Express', 'MongoDB', 'JavaScript', 'Sass', 'HTML', 'CSS']],
        ['hottakes', 'https://github.com/leCharade/hottakes', ['API', 'NodeJS', 'Express', 'MongoDB', 'JavaScript', 'Postman']],
        ['kanap', 'https://github.com/leCharade/kanap', ['JavaScript', 'Postman']],
        ['panthere', 'https://github.com/leCharade/la-panthere', ['SEO', 'Accessibility', 'Lighthouse', 'WAVE']],
        ['ohmyfood', 'https://github.com/leCharade/ohmyfood', ['Sass', 'HTML', 'CSS']],
        ['booki', 'https://github.com/leCharade/Booki', ['HTML', 'CSS']]
    ]
    
    let i = 1
    return (
        <main className="projects">
            <div className="introduction">
                <h1>{t('projects.h1')}</h1>
            </div>
            <div className="horizontal-line-1"></div>
            {   
                projects.map(project => (
                    <>
                        <article className="project" id={'project-' + project[0]}>
                            <h2 className="project project__title" id={'title-' + project[0]}>{t('projects.'+ project[0] +'.title')}</h2>
                            <h3 className="project project__subtitle" id={'subtitle-' + project[0]}>{t('projects.'+ project[0] +'.subtitle')}</h3>
                            <p className="project project__desc" id={'desc-' + project[0]}>{t('projects.'+ project[0] +'.desc')}</p>
                            <p><a href=""><FontAwesomeIcon icon={faImage} /> {t('projects.gallery')}</a></p>
                            {project[1] !== '' ? <p className="project project__link" id={'link-' + project[0]}><a href={project[1]} target="_blank"><FontAwesomeIcon icon={faCodeBranch} /> {t('projects.github')}</a></p> : null}
                            <p><a href="" className="project project__test"><FontAwesomeIcon icon={faArrowPointer} /> {t('projects.test')}</a></p>
                            <div className="technolist">
                                {project[2].map(techno => (
                                    <div className="techno">{techno}</div>
                                ))}
                            </div>
                        </article>
                        {projects.length === i ? null : <div className="horizontal-line-1"></div>}
                        <div hidden>{i++}</div>
                    </>
                ))
            }
        </main>
    )
}