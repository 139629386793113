import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import menu_fr from "./translations/fr/menu.json";
import menu_en from "./translations/en/menu.json";
import home_fr from "./translations/fr/home.json";
import home_en from "./translations/en/home.json";
import projects_fr from "./translations/fr/projects.json";
import projects_en from "./translations/en/projects.json";
import experience_fr from "./translations/fr/experience.json";
import experience_en from "./translations/en/experience.json";
import about_fr from "./translations/fr/about.json";
import about_en from "./translations/en/about.json";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'fr',                              // language to use
    resources: {
        fr: {
            menu: menu_fr,
            home: home_fr,
            projects: projects_fr,
            experience: experience_fr,
            about: about_fr     
        },
        en: {
            menu: menu_en,
            home: home_en,
            projects: projects_en,
            experience: experience_en,
            about: about_en  
        },
    },
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
